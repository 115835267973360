import "./index.css";
import axios from "axios";
import React from "react";
import Radium, { StyleRoot } from "radium";
import { Carousel } from "react-responsive-carousel";
import { fadeInRight } from "react-animations";
// SWEET ALERT NPM PACKAGE
import Swal from "sweetalert2";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const styles = {
  fadeInRight: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bornEarth: new Date("1997-09-30"),
      workingFrom: new Date("2021-04-01"),
      ageCalc: "",
      workingTimeCalc: "",
      currentYear: new Date().getFullYear(),
      //FORM STATES
      userName: "",
      userPhone: "",
      userEmail: "",
      userIntrest: "",
      //FORM STATES ENDS HERE
      apiResponse: [],
      introRef: React.createRef(),
      educationRef: React.createRef(),
      // skillRef: React.createRef(),
      workRef: React.createRef(),
      contactRef: React.createRef(),
      socialIcons: [
        {
          socialIcon: "Static0.webp",
          socialLink: "https://www.facebook.com/profile.php?id=100082869222829",
        },
        {
          socialIcon: "Static1.webp",
          socialLink: "https://github.com/Babayagacoding",
        },
        {
          socialIcon: "Static2.webp",
          socialLink: "https://www.instagram.com/babayaga_coding/",
        },
        {
          socialIcon: "Static3.webp",
          socialLink: "https://www.linkedin.com/in/babayaga-coding-a5645b251/",
        },
        {
          socialIcon: "Static4.webp",
          socialLink:
            "https://www.youtube.com/channel/UC1kpjTcO8sGHuDbmtykfUng",
        },
        {
          socialIcon: "Static5.webp",
          socialLink: "https://twitter.com/babayaga_coding",
        },
        {
          socialIcon: "Static6.webp",
          socialLink: "https://wa.me/9971383757",
        },
        {
          socialIcon: "Static7.webp",
          socialLink: "https://developer.silkroutevacation.com",
        },
      ],
      SliderData: ["NorthIndia0.webp", "NorthIndia1.webp"],
      ddmProject: [
        "HomePageCropped.webp",
        "BelowPageCropped.webp",
        "BlogPageCropped.webp",
        "ContactUsCropped.webp",
      ],
      northIndiaProject: ["NorthIndia0.webp", "NorthIndia1.webp"],
      deNovo: [
        "DenovoIndia0.webp",
        "DenovoIndia1.webp",
        "DenovoIndia2.webp",
        "DenovoIndia3.webp",
      ],
      silkRouteVacation: [
        "SilkRoute0.webp",
        "SilkRoute1.webp",
        "SilkRoute2.webp",
      ],
    };
    this.workClick = this.workClick.bind(this);
    this.introClick = this.introClick.bind(this);
    this.educationClick = this.educationClick.bind(this);
    this.contactClick = this.contactClick.bind(this);
    this.onClickProject = this.onClickProject.bind(this);
    this.formOnchangeHandle = this.formOnchangeHandle.bind(this);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("https://www.developer.treebiz.in:5000/portfolioContact", {
        cx_name: this.state.userName,
        cx_number: this.state.userPhone,
        cx_email: this.state.userEmail,
        cx_intrest: this.state.userIntrest,
      })
      .then((res) => {
        console.log(res.data.respMsg);
        if (res.data.respMsg === "Data Submitted Successfully") {
          Swal.fire({
            title: "Congratulations",
            text: "Your form has been submitted successfully",
            icon: "success",
          });
          //JS Code for putting a click event on button
          const Button = document.getElementById("animatedbutton");
          const ButtonText = document.getElementById("text");
          const ButtonIcon = document.getElementById("buttonicon");
          Button.classList.add("simpleAnimation");
          ButtonText.classList.add("displaynone");
          ButtonIcon.classList.remove("displaynone");
          //JS Code for putting a click event on button Ends Here
          this.setState({
            userName: "",
            userPhone: "",
            userEmail: "",
            userIntrest: "",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer:
              "Due to some technical issue your query is not been able to submit. Please try again later thank youś",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    const { bornEarth, workingFrom } = this.state;
    // Function to calculate the difference in years between two dates
    function calculateAge(startDate, endDate) {
      const startYear = startDate.getFullYear();
      const startMonth = startDate.getMonth();
      const endYear = endDate.getFullYear();
      const endMonth = endDate.getMonth();

      const ageInMonths = (endYear - startYear) * 12 + (endMonth - startMonth);

      // Calculate years and remaining months
      const years = Math.floor(ageInMonths / 12);
      const months = ageInMonths % 12;

      return { years, months };
    }
    const currentDate = new Date(); // Today's date
    this.setState({
      ageCalc: calculateAge(bornEarth, currentDate),
      workingTimeCalc: calculateAge(workingFrom, currentDate),
    });
    document.addEventListener("scroll", this.trackScrolling);
  }

  trackScrolling = () => {
    const Header = document.getElementById("Header");
    if (window.pageYOffset === 0) {
      Header.classList.remove("HeaderStickyInMobile");
    } else {
      Header.classList.add("HeaderStickyInMobile");
    }
  };

  formOnchangeHandle(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onClickProject(event) {
    const ddmProject = this.state.ddmProject;
    const northIndiaProject = this.state.northIndiaProject;
    const denovo = this.state.deNovo;
    const silkroutevacation = this.state.silkRouteVacation;

    const AllButtons = document.querySelectorAll(".btn-info");

    if (event.target.textContent === "North India Car Rental") {
      AllButtons[0].classList.add("activeClass");
      AllButtons[1].classList.remove("activeClass");
      AllButtons[2].classList.remove("activeClass");
      AllButtons[3].classList.remove("activeClass");
      this.setState({ SliderData: northIndiaProject });
    } else if (event.target.textContent === "DDM Public School") {
      AllButtons[0].classList.remove("activeClass");
      AllButtons[1].classList.remove("activeClass");
      AllButtons[2].classList.add("activeClass");
      AllButtons[3].classList.remove("activeClass");
      this.setState({ SliderData: ddmProject });
    } else if (event.target.textContent === "De Novo") {
      AllButtons[0].classList.remove("activeClass");
      AllButtons[1].classList.remove("activeClass");
      AllButtons[2].classList.remove("activeClass");
      AllButtons[3].classList.add("activeClass");
      this.setState({ SliderData: denovo });
    } else if (event.target.textContent === "Silk Route Vacation") {
      AllButtons[0].classList.remove("activeClass");
      AllButtons[1].classList.add("activeClass");
      AllButtons[2].classList.remove("activeClass");
      AllButtons[3].classList.remove("activeClass");
      this.setState({ SliderData: silkroutevacation });
    }
  }

  introClick(event) {
    const { introRef } = this.state;
    if (event.target.innerHTML === "Introduction") {
      window.scrollTo(0, introRef.current.offsetTop);
    }
  }

  workClick(event) {
    const { workRef } = this.state;
    if (event.target.innerHTML === "Work Experience") {
      window.scrollTo(0, workRef.current.offsetTop);
    }
  }

  contactClick(event) {
    const { contactRef } = this.state;
    if (event.target.innerHTML === "Contact") {
      window.scrollTo(0, contactRef.current.offsetTop);
    }
  }

  educationClick(event) {
    const { educationRef } = this.state;
    if (event.target.innerHTML === "Education") {
      window.scrollTo(0, educationRef.current.offsetTop);
    }
  }

  render() {
    const {
      userName,
      userPhone,
      userEmail,
      userIntrest,
      socialIcons,
      introRef,
      educationRef,
      workRef,
      contactRef,
      SliderData,
      ageCalc,
      workingTimeCalc,
      currentYear,
    } = this.state;

    const GifAnimation = (event) => {
      let i = 0;
      const AllStatic = document.querySelectorAll(".SocialIcons");
      for (i; i < AllStatic.length; i++) {
        if (AllStatic[i] === event.target) {
          event.target.src = `/Assets/SocialIcon/WithoutBack-${i}.gif`;
        }
      }
    };
    const removegifAnim = (event) => {
      let i = 0;
      const AllStatic = document.querySelectorAll(".SocialIcons");
      for (i; i < AllStatic.length; i++) {
        if (AllStatic[i] === event.target) {
          event.target.src = `/Assets/SocialIcon/Static/Static${i}.webp`;
        }
      }
    };

    const PreviousButton = (clickHandler, hasPrev, label) => {
      return (
        <button className="MyCssForPrev" type="button" onClick={clickHandler}>
          <div></div>
        </button>
      );
    };
    const NextButton = (clickHandler, hasNext, label) => {
      return (
        <button className="MyCssForNext" type="button" onClick={clickHandler}>
          <div></div>
        </button>
      );
    };

    return (
      <StyleRoot>
        <header id="Header" className="Header">
          <div className="PortfolioLogo">
            <img
              id="logoGif"
              className="Logo"
              src="/Assets/LogoRelated/WithAnimation.gif"
              alt="Animated Logo"
            />
          </div>
          <div className="MenuList">
            <span onClick={this.introClick}>Introduction</span>
            <span onClick={this.educationClick}>Education</span>
            <span onClick={this.workClick}>Work Experience</span>
            <span onClick={this.contactClick}>Contact</span>
          </div>
          <div className="SocialIconDiv">
            {socialIcons.length
              ? socialIcons.map((data, index) => (
                  <div key={index}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      href={data.socialLink}
                    >
                      <img
                        className="SocialIcons"
                        alt="Not Found"
                        src={`/Assets/SocialIcon/Static/${data.socialIcon}`}
                        onMouseOver={GifAnimation}
                        onMouseLeave={removegifAnim}
                      />
                    </a>
                  </div>
                ))
              : null}
          </div>
        </header>
        <main id="MainPage">
          <div id="StarterDiv" className="FrontDiv" ref={introRef}>
            <div className="Headbox" style={styles.fadeInRight}>
              <h1 className="MainHeading">Hi</h1>
              <br />
              <h2 className="MainHeading">
                I'm <span className="threeDtext">K</span>rishan{" "}
                <span className="threeDtext">K</span>ant Adarsh
              </h2>
              <br />
              <h3 className="MainHeading">
                You can call me <span className="threeDtext">K</span>ay...
              </h3>
              <p className="ThanksGiving">
                Thanks for visiting my portfolio, I hope you are doing fine.
              </p>
            </div>
          </div>
          <div className="MyIntro" ref={educationRef}>
            <div className="secMargin">
              <h2 className="secHead">Education</h2>
              <p className="secText">
                Working as a full stack developer for more than{" "}
                {workingTimeCalc.years} years and {workingTimeCalc.months}{" "}
                months, creating basic web application to creating complex web
                application projects like e-commerce and many more. I am
                passionate about my work. I am ambitious and driven. Front End
                Design using basic HTML and Css or by using Mern stack. How much
                I am good at Front End Design you can get an assumption by this
                single page portfolio which is made up in React.js and in
                Backend I am using Express.js. JavaScript and their framework
                are best in performance and have almost every thing which is
                required in modern web or any kind of application development.
                Always open to learn new things and to work on challenging
                projects to learn new things and skills.
              </p>
            </div>
            <div className="OuterDiv">
              <div className="LeftDiv">
                <div className="secMargin">
                  <h3>About Myself</h3>
                  <p className="secText">
                    As you already know my name, Let me tell you a bit more
                    about myself I am {ageCalc.years} years and {ageCalc.months}{" "}
                    months old and having full stack web developer working in
                    this field from past 2 years and learned a lot. I was born
                    and brought up in Delhi, India. My education qualifications
                    are as follows
                  </p>
                  <ul className="secText">
                    <li>
                      Completed Matriculation (X) in 2013 from CBSE board
                      {/* <br /> */}
                      {/* <span>6.2 CGPA</span> */}
                    </li>
                    <li>
                      Completed Intermediate (XII) in 2015 from CBSE board
                      {/* <br /> */}
                      {/* <span>60 Percentage</span> */}
                    </li>
                    <li>
                      Completed B.Tech (Mechanical and Automation Engineering)
                      in 2019 from Guru Gobind Singh University Delhi, India
                      {/* <br /> */}
                      {/* <span>57 Percentage</span> */}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="RightDiv">
                <img
                  className="SvgIcon"
                  alt="Not Found"
                  src="/Assets/MainHomeBanner/SVG1.png"
                />
              </div>
            </div>
          </div>
          <div id="WorkExp" className="experienceOuter" ref={workRef}>
            <div className="WorkExpdesign">
              <h2
                className="secHead"
                style={{ color: "white", textAlign: "center" }}
              >
                Work Experience
              </h2>
            </div>
            <div className="RoadMap">
              <div className="leftSideExpDiv">
                <span className="leftsideLinker"></span>
                <h5> January 2022 - Still Working</h5>
                <span className="SimpleBorder"></span>
                <h3>Full Stack Web Developer</h3>
                <h5>North India Car Rental Pvt Ltd</h5>
                <ul>
                  <li>
                    Full Stack Web Developer North India Car Rental Pvt Ltd
                    Worked over 2 complex web application projects developed
                    both front-end and backend of the web application. The whole
                    web application is working over dynamic data coming from
                    database, created an admin panel for the web application
                    from where data can be modified and placed orders can be
                    checked. One is a tourism web application and other one is
                    an online gift shop (E-commerce) web application.
                  </li>
                  <li>
                    Also managed the back-end where data management is crucial
                    for building a dynamic web application, completed this work
                    by myself using PHP (Framework Laravel) and MySQL
                    (Database).
                  </li>
                  <li>
                    Language's used during projects:
                    <ul className="subList">
                      <li>Laravel in Backend</li>
                      <li>React.js in Front end</li>
                    </ul>
                  </li>
                  <li>
                    Web applications worked on:
                    <ul className="subList">
                      <li>
                        <a
                          className="ProjectLinks"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href="https://www.northindiacarrental.com"
                        >
                          North India Car Rental
                        </a>
                      </li>
                      <li>
                        <a
                          className="ProjectLinks"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href="https://www.silkroutevacation.com"
                        >
                          Silk Route Vacation
                        </a>
                      </li>
                      <li>
                        <a
                          className="ProjectLinks"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href="https://www.careerwalks.in"
                        >
                          Career Walks
                        </a>
                      </li>
                      <li>
                        <a
                          className="ProjectLinks"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href="https://www.runestattoos.com"
                        >
                          Runes Tattoos
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <span className="RoadLine"></span>
              <div className="rightSideExpDiv">
                <span className="rightsideLinker "></span>
                <h5>April 2021 - September 2021</h5>
                <span className="SimpleBorder"></span>
                <h3>Web Developer Intern</h3>
                <h5>Advegis Media Pvt Ltd</h5>
                <ul>
                  <li>
                    In my internship period worked over 12+ projects of web
                    application building, designing and successfully submitted
                    multiple projects worked over both front-end and back-end of
                    web application.
                  </li>
                  <li>
                    Also managed the back-end where data management is crucial,
                    completed this work by myself using PHP and MySQL.
                  </li>
                  <li>
                    Language's used during projects:
                    <ul className="subList">
                      <li> HTML</li>
                      <li>CSS</li>
                      <li>JavaScript</li>
                      <li>PHP</li>
                      <li>MySQL</li>
                      <li>Bootstrap</li>
                      <li>Jquery</li>
                    </ul>
                  </li>
                  <li>
                    Web applications worked on:
                    <ul className="subList">
                      <li>
                        <a
                          className="ProjectLinks"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href="https://www.ddmgroup.in"
                        >
                          DDM Group of Schools (Korba, Chattisgarh)
                        </a>
                      </li>
                      <li>
                        <a
                          className="ProjectLinks"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href="https://www.denovoindia.com"
                        >
                          De Novo India
                        </a>
                      </li>
                      <li>
                        <a
                          className="ProjectLinks"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href="https://www.inspirationias.com"
                        >
                          Inspiration Ias Academy
                        </a>
                      </li>
                      <li>
                        <a
                          className="ProjectLinks"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href="https://www.knsvolvo.com"
                        >
                          Volvo car dealership
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="ProjectsSection">
            <h2
              className="secHead"
              style={{ color: "black", textAlign: "center" }}
            >
              Project Completed
            </h2>
            <div className="ProjectPreviewButton">
              <button
                className="btn btn-info activeClass"
                onClick={this.onClickProject}
              >
                North India Car Rental
              </button>
              <button className="btn btn-info" onClick={this.onClickProject}>
                Silk Route Vacation
              </button>
              <button className="btn btn-info" onClick={this.onClickProject}>
                DDM Public School
              </button>
              <button className="btn btn-info" onClick={this.onClickProject}>
                De Novo
              </button>
            </div>
            <div className="PerProjectShower">
              <Carousel
                swipeable={true}
                stopOnHover={true}
                showArrows={true}
                interval={5000}
                renderArrowPrev={PreviousButton}
                renderArrowNext={NextButton}
                autoPlay
                showStatus={false}
                useKeyboardArrows={true}
                showThumbs={false}
              >
                {SliderData.length
                  ? SliderData.map((data, index) => (
                      <div key={index}>
                        <LazyLoadImage
                          alt="Not Found"
                          effect="blur"
                          src={`/Assets/SingleFolderAllProjectImages/${data}`}
                        />
                      </div>
                    ))
                  : null}
              </Carousel>
            </div>
          </div>
          <div className="Contact_us" ref={contactRef}>
            <h2
              className="secHead"
              style={{ color: "white", textAlign: "center" }}
            >
              Want to grow your buisness contact us
            </h2>
            <form onSubmit={this.handleSubmit} className="ourFormOuterCss">
              <div>
                <label htmlFor="fname" className="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  id="fname"
                  name="userName"
                  value={userName}
                  className="form-control"
                  onChange={this.formOnchangeHandle}
                  required
                />
              </div>
              <div>
                <label htmlFor="custNumber">Your Contact Number</label>
                <input
                  type="text"
                  id="custNumber"
                  name="userPhone"
                  value={userPhone}
                  className="form-control"
                  onChange={this.formOnchangeHandle}
                  required
                />
              </div>
              <div>
                <label htmlFor="custEmail">Your Email</label>
                <input
                  type="text"
                  id="custEmail"
                  name="userEmail"
                  value={userEmail}
                  className="form-control"
                  onChange={this.formOnchangeHandle}
                  required
                />
              </div>
              <div>
                <label htmlFor="service">Intrested Service</label>
                <select
                  id="service"
                  name="userIntrest"
                  value={userIntrest}
                  className="form-select"
                  onChange={this.formOnchangeHandle}
                  required
                >
                  <option value="Content Writing">Content Writing</option>
                  <option value="Blog Writing">Content Writing</option>
                  <option value="Seo Optimization">Seo Optimization</option>
                  <option value="Web_App">Web Application</option>
                  <option value="Mob_App">Mobile Application</option>
                  <option value="Front_End">Front End Designing</option>
                  <option value="Back_End">Back End Development</option>
                  <option value="Need_Host">
                    Need Hosting For Your Website
                  </option>
                </select>
              </div>
              <button
                id="animatedbutton"
                className="animatedsubmit"
                type="submit"
              >
                <span id="text">Submit</span>
                <img
                  alt="none"
                  id="buttonicon"
                  className="displaynone TickIcon"
                  src="/Assets/Icons/check.png"
                />
              </button>
            </form>
          </div>
          <div id="footerSection">
            <p
              style={{
                textAlign: "center",
                padding: "5px 10px",
                margin: "0px",
              }}
            >
              {currentYear} ©️ Babayaga Coding All rights reserved.
            </p>
          </div>
        </main>
      </StyleRoot>
    );
  }
}

export default App;
